/* Navbar.css */

/* Custom CSS for Hamburger Animation */
.hamburger span {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.hamburger.open span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}
