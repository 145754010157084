/* ZoomAnimated.css */

/* Initial state for the zoom-in animation */
.scale-in,
.scale-in-delayed {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

/* Animation class to be added when the section is in view */
.animate {
  opacity: 1;
  transform: scale(1);
}

/* Additional styles for delayed animation */
.scale-in-delayed {
  transition-delay: var(--delay, 0s); /* Use CSS variable for delay */
}
